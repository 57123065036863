
$theme-colors: (
  "primary": #343a40,
  "secondary": #9e8eb7,
  "success": #45b649,
  "danger": #e6664f,
  "warning": #ffd700,
  "info": #00c9ff
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 224px;
